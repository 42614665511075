import { event } from "nextjs-google-analytics";
import { IQuote, IUser } from "types";
import { CoverageType } from "./constants/generic";
import { AnalyticsUser } from "@getaddify/lul";
import posthog from "posthog-js";

type GaItem = RequireOnlyOne<
  {
    item_id: string;
    item_name: string;
    affiliation?: string;
    coupon?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string | null;
    location_id?: string;
    price?: number;
    quantity?: number;
  },
  "item_id" | "item_name"
>;

type GaEvent = {
  currency: string;
  value: number;
  transaction_id?: string;
  items: GaItem[];
};

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
    Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export const getGaEventObject = (quote: IQuote): GaEvent => {
  const items = quote.lineItems.map((line, index) => {
    return <GaItem>{
      item_name: line.name,
      price: line.price / 100,
      quantity: line.quantity,
      item_variant: line.referralPartner,
      index,
    };
  });

  return {
    currency: "USD",
    value: quote.total / 100,
    items,
  };
};

export const getGTag = (url: string): string => {
  if (!url) {
    return "";
  }

  if (url.includes("staging")) {
    return "";
  } else if (url.includes("localhost")) {
    return "";
  }

  return "G-4BYDVY1BKM";
};

export enum GaActions {
  Onboarding = "Onboarding",
  Personal_Info = "Personal_Info",
  Business_Info = "Business_Info",
  Vehicle_Added = "Vehicle_Added",
  Vehicle_Failed = "Vehicle_Failed",
  Fleet_Step_One = "Fleet_Step_One",
  Fleet_Complete = "Fleet_Complete",
  Quote_Created = "Quote_Created",
  Account_Setup_Success = "Account_Setup_Success",
  Account_Setup_Failure = "Account_Setup_Failure",
  Rental_Agreement_Added = "Rental_Agreement_Added",
  Loss_Runs_Added = "Loss_Runs_Added",
  Application_Pending = "Application_Pending",
  Documents_Signed = "Documents_Signed",
  Checkout_Complete = "Checkout_Complete",
  Contact_Us_Clicked = "Contact_Us_Clicked",
  Deductible_1k_3k = "Deductible_1k_3k",
  Deductible_5k = "Deductible_5k",
}

export enum GaStandardActions {
  Generate_Lead = "generate_lead", //Quote Created
  Account_Created = "sign_up",
  Begin_Checkout = "begin_checkout",
  Add_Payment_Info = "add_payment_info",
  Purchase = "purchase",
}

export enum GaCategories {
  Continuous_Coverage = "Continuous_Coverage",
  ORP = "ORP",
  Landing_Page = "Landing_Page",
}

export const GaEvent = (
  action: GaActions | GaStandardActions,
  category: GaCategories | CoverageType | undefined,
  eventObject?: GaEvent | IQuote
): void => {
  const gtag = getGTag(window?.location?.host);

  if (gtag) {
    event(action, {
      event_category: category || CoverageType.ContinuousCoverage,
      ...eventObject,
    });
  }

  posthog.capture(action, { category, ...eventObject });
};

export const SetUser = (user: AnalyticsUser | null): void => {
  if (!user) {
    return;
  }

  //set user details in GA
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "setUser",
      userId: user.id,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userEmail: user.email,
      userPhone: user.phoneNumber,
      userCompany: user.companyName,
      userCoverageType: user.coverageType,
    });
  }

  posthog.identify(user.id, {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
    company: user.companyName,
    coverageType: user.coverageType,
  });

  posthog.group("coverageType", user.coverageType);
};

export const SetUserFromUser = (
  user: IUser | null,
  coverageType?: CoverageType
): void => {
  SetUser({
    id: user?.id || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    companyName: user?.companyName || "",
    coverageType: coverageType || CoverageType.ContinuousCoverage,
  });
};
