import { ERROR_MESSAGES, MILEAGE_VALUES, VIN_REGEX } from "./constants";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getClassName = (cols) => {
  switch (cols) {
    case 1:
      return "col-span-1";
    case 2:
      return "col-span-2";
    case 3:
      return "col-span-3";
    case 4:
      return "col-span-4";
    case 5:
      return "col-span-5";
    case 6:
      return "col-span-6";
    case 7:
      return "col-span-7";
    case 8:
      return "col-span-8";
    case 9:
      return "col-span-9";
    case 10:
      return "col-span-10";
    case 11:
      return "col-span-11";
    case 12:
      return "col-span-12";
  }
};

export function uniqueArray(array) {
  return [...new Set(array)];
}

export function getNestedObject(object, keyArray) {
  return keyArray.reduce((a, prop) => a[prop] || {}, object);
}

export const getErrorMessage = (errors, name) => {
  try {
    const isArrayInputRegex = /(\w+)\[(\d+)\]\.(.*)/i;

    if (!errors || !name) return undefined;

    const match = name.match(isArrayInputRegex);

    // non array input
    if (!match) {
      if (errors?.[name]?.types)
        return Object.values(errors[name].types).join("; ");
      return errors?.[name]?.message;
    }

    const getArgs = (match) => {
      const { 1: key, 2: index, 3: rest } = match;
      const _match = rest.match(isArrayInputRegex);

      // nested arrays
      if (_match) return [key, index, ...getErrorMessage(_match)];

      return [key, index, rest];
    };

    const args = getArgs(match);
    const error = getNestedObject(errors, args);
    if (error?.types) return Object.values(error.types).join("; ");
    else return error?.message;
  } catch (error) {
    return undefined;
  }
};

export function formatPhoneNumber(phoneNumber, { key, setValue } = {}) {
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const formatted = "(" + match[1] + ") " + match[2] + "-" + match[3];
    if (setValue) setValue(key, formatted, { shouldValidate: true });
    return formatted;
  }
  return phoneNumber;
}

export function formatVin(vin, { key, setValue } = {}) {
  const uppercase = (vin || "").toUpperCase();
  if (setValue) setValue(key, uppercase, { shouldValidate: true });
  return uppercase;
}

export function formatDate(timestamp) {
  if (!timestamp) return "";
  const date = new Date(timestamp * 1000);

  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
}

export function formatEin(ein, { key, setValue } = {}) {
  var cleaned = ("" + ein).replace(/\D/g, "");
  var match = cleaned.match(
    /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])(\d{7})$/
  );
  if (match) {
    const formatted = match[1] + "-" + match[2];
    if (setValue) setValue(key, formatted, { shouldValidate: true });
    return formatted;
  }
  return ein;
}

export function formatStripeDollarValue(value) {
  if (!value) return "$0.00";
  return formatCurrency(value / 100);
  // return `$${parseFloat(value / 100).toFixed(2)}`;
}

export function formatDollarValue(value) {
  if (!value) return "$0.00";
  return `$${parseFloat(value).toFixed(2)}`;
}

export const formatCurrency = (num) => {
  if (!num) return "$0.00";
  return `$${parseFloat(num.toString())
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}`;
};

export const vinValidationError = (errorMessage, key, setError) => {
  const errors = errorMessage?.split("; ");
  const errorTypes = errors.reduce((acc, error) => {
    if (error.startsWith("1 - "))
      acc.push({ type: "pattern", message: error.split("1 - ")[1] });
    else if (error.startsWith("7 - "))
      acc.push({ type: "manufacturer", message: error.split("7 - ")[1] });
    else if (error.startsWith("11 - "))
      acc.push({ type: "year", message: error.split("11 - ")[1] });
    else if (error.startsWith("400 - "))
      acc.push({ type: "character", message: error.split("400 - ")[1] });
    return acc;
  }, []);
  setError(key, {
    types: errorTypes.reduce((acc, { type, message }) => {
      acc[type] = message;
      return acc;
    }, {}),
  });
};

export const updateVinData = async (
  { vin, mileage, index },
  { update, append, setError } = {}
) => {
  // if (VIN_REGEX.test(vin)) {
  try {
    const vinData = await fetch(`/api/vin/${vin}`).then((res) => res.json());
    if (vinData.error)
      throw new Error(vinData.error?.message || "An error has occurred");
    const data = {
      vin,
      year: vinData.ModelYear,
      make: vinData.Make,
      model: vinData.Model,
    };
    if (mileage) data.mileage = mileage;
    if (update && append) {
      if (index || index === 0) update(Number(index), data);
      else append(data);
    }
    return data;
  } catch (error) {
    if (!setError) return { vin, mileage, error, invalid: true };
    try {
      vinValidationError(error?.message, `fleet[${index}].vin`, setError);
    } catch (error) {
      setError(`fleet[${index}].vin`, {
        type: "pattern",
        message: ERROR_MESSAGES.vin_pattern,
      });
    }
  }
  // }
  // incorrect vin
  // else {
  //   const data = {
  //     vin,
  //     year: null,
  //     make: null,
  //     model: null,
  //     mileage,
  //   };
  //   if (update && index) update(Number(index), data);
  //   return data;
  // }
};

export const onRemove = (
  { record, index },
  { objectsToDelete, setObjectsToDelete }
) => {
  if (record._id) setObjectsToDelete([...objectsToDelete, record._id]);
};

const mileageMap = MILEAGE_VALUES.reduce((accumulator, { label, ...value }) => {
  accumulator[label] = value;
  return accumulator;
}, {});

export const getMileageValueFromLabel = (label) => {
  if (!label) return "";
  let mileage = (mileageMap[label].max + mileageMap[label].min) / 2;
  if (mileage === Infinity)
    mileage = MILEAGE_VALUES[MILEAGE_VALUES.length - 1].min;
  return mileage;
};

export const getVehiclesFromCSV = (rows) => {
  return rows
    .map(({ vin, mileage: _mileage } = {}) => {
      if (!vin) return null;
      if (!_mileage) return { vin };

      if (!isNaN(_mileage)) {
        const mileage = MILEAGE_VALUES.find(
          ({ min, max, label }) => _mileage >= min && _mileage <= max
        )?.label;
        if (mileage) return { vin, mileage };
      }

      if (MILEAGE_VALUES.some(({ label }) => label === _mileage))
        return { vin, mileage: _mileage };
      else return { vin };
    })
    .filter((x) => x);
};

export const fetcher = (url, method = "GET", data) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  if (data) {
    options.body = JSON.stringify(data);
  }
  return fetch(url, options).then((res) => res.json());
};

export const removeEmptyProps = (obj = {}) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
};

export function resolve(path, obj) {
  return path.split(".").reduce(function(prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || self);
}

export const idGenerator = (len) => {
  let id = "";
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < len; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
};

export const MS_IN_DAY = 1000 * 60 * 60 * 24;
