export const VIN_REGEX =
  /^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dX][A-HJ-NPR-Za-hj-npr-z\d]{2}\d{6}$/;
export const EIN_REGEX =
  /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/g;
export const PHONE_REGEX =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;

export const DRIVERS_LICENSE_NUMBER_REGEX = /^[a-zA-Z0-9-]*$/g;

export const MILEAGE_VALUES = [
  { min: 0, max: 25000, label: "0 - 25,000" },
  { min: 25001, max: 50000, label: "25,001 - 50,000" },
  { min: 50001, max: 75000, label: "50,001 - 75,000" },
  { min: 75001, max: 100000, label: "75,001 - 100,000" },
  { min: 100001, max: 125000, label: "100,001 - 125,000" },
  { min: 125001, max: 150000, label: "125,001 - 150,000" },
  { min: 150001, max: 175000, label: "150,001 - 175,000" },
  { min: 175001, max: 200000, label: "175,001 - 200,000" },
  { min: 200001, max: 225000, label: "200,001 - 225,000" },
  { min: 225001, max: 250000, label: "225,001 - 250,000" },
  { min: 250001, max: Infinity, label: "Greater than 250,000" },
];

export const FEATURE_FLAGS = {
  TUMS_PUSH_PADDOCKS_MEMBER: "TUMS_PUSH_PADDOCKS_MEMBER",
  /*RENTAL_AGREEMENT_ENABLED: "FE_TOGGLE_SELF_ONBOARDING_RENTAL_AGREEMENT",
  LOSS_RUNS_ENABLED: "FE_TOGGLE_SELF_ONBOARDING_LOSS_RUNS",
  APPLICATION_FLOW_ENABLED: "FE_TOGGLE_SELF_ONBOARDING_APPLICATION_WAIT",*/
};

export enum DB_COLLECTIONS {
  ONBOARDING = "onboarding",
  ACCOUNTS = "accounts",
}

export enum DB_SUB_COLLECTIONS {
  VEHICLES = "vehicles",
  CHANGE_LOGS = "changelog",
  DOCUMENTS = "documents",
  EMAILS_SENT = "emailsSent",
}

export const ERROR_MESSAGES = {
  required: true,
  vin_pattern: "Invalid VIN. Please correct and try again.",
  phone_pattern: "Invalid phone number.",
  ein_pattern: "Invalid EIN number.",
  drivers_license_number_pattern: "Invalid drivers license number.",
};

export const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const SITES = {
  TRUCKING: "trucking",
  RENTALS: "getaddify",
  ONBOARDING: "onboarding",
};

export const UNDERWRITING_STATUS = {
  INCOMPLETE: "Incomplete",
  APPROVED: "Approved",
  DECLINED: "Declined",
  UNDER_REVIEW: "Under Review",
  LOADING: "In Review",
};

export const UNDERWRITING_RESTRICTIONS = {
  CONTINUOUS_COVERAGE_MIN_VEHICLES: 5,
  CONTINUOUS_COVERAGE_MIN_APPROVED_REVIEW_VEHICLES: 3,
};

export const INSURANCE_STATUS = {
  APPROVED: "Approved",
  DECLINED: "Declined",
  UNDER_REVIEW: "Under Review",
};

export const VEHICLE_STATUS = {
  INACTIVE: "Inactive",
  ACTIVE: "Active",
  REQUESTED: "Requested",
  PENDING: "Pending",
  ARCHIVE: "Archive",
};

export const APPLICATION_TYPE = {
  ONBOARDING: "onboarding",
  TRUCKING: "trucking",
  CARS: "cars",
};

export const BUSINESS_LINE = {
  TRUCKING: "Commercial Trucking",
  AUTO: "Commercial Auto",
};

export const UNDERWRITING_MODE = {
  VEHICLES: "vehicles",
  TRUCKING: "trucking",
};

export const ONBOARDING = {
  NOW: "now",
  LATER: "later",
};

export const ACCOUNT_STATUS = {
  ACTIVE: "Active",
  PENDING_ACTIVATION: "Pending Activation",
};

export const TOOLTIP_MESSAGE = {
  UNDER_REVIEW:
    "Please fill out this info while your vehicle is being reviewed",
};

export const BOOLEAN_SELECT_OPTIONS = ["Yes", "No"];

export const ELD_PROVIDER_OPTIONS = [
  "Motive (Formerly KeepTruckin)",
  "Samsara",
  "Geotab",
  "Verizon Connect",
  "Omnitracs",
  "Cisco Systems",
  "Trimble",
  "Platform Science",
  "Octo Telematics",
  "MiX Telematics",
  "Teletrac Navman",
  "Zonar Systems",
  "Other",
  "N/A",
];

export const VEHICLES_OPERATED_OPTIONS = [
  "Dry Van / Box",
  "Flat Bed",
  "Automobile Hauler",
  "Refrigerated Freight",
  "Oversize / Overweight",
  "Containerized Freight",
  "Household Goods",
  "Double Trailer",
  "Mobile Home Haulers",
];

export const OPERATION_RADIUS_OPTIONS = [
  "0 - 300 miles",
  "301 - 500 miles",
  "501 - 1,500 miles",
  "Over 1,501 miles",
];
